.content-wrapper {
    height: 100vh;
    height: var(--inner-height);

    width: calc(100vw - env(-safe-area-inset-left) - env(-safe-area-inset-right));
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:not(.isApp) {
        background-size: cover;
        background-image: url('/team-background.png');
        background-position: 50%;
    }


    .tobit-team-runtime-content {
        width: 100%;
        overflow: scroll;
        flex-shrink: 1;
        -webkit-overflow-scrolling: touch !important;
        flex-grow: 1;

        &::-webkit-scrollbar-track {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }
    }
}

.inner-content-wrapper {
    display: flex;

    justify-content: space-between;
    flex-direction: row;
    flex-grow: 1;
}

body {
    html & {
        overflow: hidden !important;
    }

    background-color: unset !important;

    .margin-wrapper {
        margin: auto;
        height: 100%;
        transition: filter 0.2s ease-in;

        .scanner-overlay-background{
            backdrop-filter: blur(4px);
            position: absolute;

            width: 100%;
            height: 100%;
            z-index: 2;
            background: radial-gradient(ellipse at center, rgba(0,0,0,0.65) 0, rgba(0,0,0,0.75) 100%);
            transition: opacity 0.2s ease-in-out, backdrop-filter .2s ease-in-out, -webkit-backdrop-filter .2s ease-in-out;
        }

        &.menu-active {
            filter: blur(4px);

            * {
                pointer-events: none;
            }
        }
    }

    * {
        box-sizing: border-box;
    }
}

* {
    border-width: 0;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &:not(ul) {
        margin: 0;
        padding: 0;
    }
}

.smart-client:not(.preciser):not(.preciser):not(.preciser):not(.preciser) {
    background-color: unset !important;
}

.bottom-bar-height .new-event-floating-button {
    bottom: var(--bottom-bar-height);
}

.mail-item-info-subtitle .emojione {
    user-select: none;
    width: auto;
    display: inline-block;
    font-size: inherit;
    height: 3ex;
    line-height: normal;
    margin: -.2ex 0.15em .2ex;
    min-height: 15px;
    min-width: 15px;
    vertical-align: middle;
}

nextjs-portal {
    display: none;
}
