@import '../../mixins/media-querys';
@import '../../constants/device-width';
@import '../../constants/z-index';
@import '../../constants/dialog';

.dialog {
  overflow: hidden;

  width: $dialog-width-min;

  @include mobile {
    width: $dialog-width-mobile;
  }

  @include desktop {
    width: $dialog-width-desktop;
  }

  //position
  position: fixed;
  z-index: $Z_DIALOG;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //font & colors
  background-color: #FFFFFF;
  font-family: 'Roboto',
  'Tahoma',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  serif;
  font-size: 14px;
  line-height: 23px;

  padding-bottom: 10px;

  &__background-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: $Z_DIALOG_BGLayer;
  }

  &__header {
    margin-bottom: 5px;

    .headline {
      margin: $dialog-component-margin;
      font-size: 24px;
    }

    .description {
      margin: $dialog-component-margin;
      color: #545454;
    }

    .search {
      width: calc(100% - 26px);
      margin: $dialog-component-margin;
      padding: 3px;
      display: block;
      font: inherit;
      line-height: inherit;
    }
  }

  &__header~&__content {
    max-height: calc(100vh - 280px);
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 90px);
  }

  &__buttons {
    text-align: center;
    margin: $dialog-component-margin;

    .button {
      min-width: 90px;
      margin: 0 5px;
    }
  }
}

.api-dialog {
  color: hsl(0, 0%, 13%) !important;
  background-color: hsl(0, 0%, 100%);
}


.color__dialogHeader {
  color: var(--chayns-color--primary);
}

.header__description a {
  color: var(--chayns-color--primary);
}

.api-dialog:has(> .api-dialog__content-custom) {
  padding-bottom: var(--app-bottom-height);
}


.api-dialog__content {
  --dialogItemSelectedBackgroundColor: var(--chayns-color--203);
  padding-bottom: var(--app-bottom-height);
}

.color__dialogBackgroundColor {
  background-color: #ffffff;
}

.color__dialogScrollbarBackgroundColor::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.color__dialogItemSelectedBackgroundColor {
  background-color: var(--chayns-color--203);
}

.color__dialogTimeListBackgroundColor {
  background-color: #ffffff;
}

.time-select-list__item:hover {
  background-color: var(--chayns-color--102);
}

.color__dialogDateDay:after {
  color: #222222;
}

.color__baseTextColor {
  color: #222222 !important;
}

.dialog-root {
  z-index: 106;

  .api-dialog {
    max-width: 412px;
    min-width: 412px;
  }
}

.chayns--mobile,
.chayns--app {
  .api-dialog {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
}

.api-dialog__content-custom {
  z-index: 7;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 40px;
  -webkit-overflow-scrolling: touch; // iOS fix
  scroll-behavior: smooth;

  .chayns--desktop & {
    max-height: 500px;

    .api-dialog--big & {
        max-height: 100%;
    }
  }
}

