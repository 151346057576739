.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 109;

  & video {
    max-width: 90vw;
    max-height: 90vh;
    min-height: 75vh;
    min-width: 75vw;
  }
}
