.transition--fade {
  transition: opacity 0.5s ease-in-out !important;
}

.fade-out {
  opacity: 0 !important;
}

.no-select {
  user-select: none;
}

.hidden {
  display: none;
}

.sortable-ghost {
  opacity: 0;
}

.search_result_wrapper {
  .react-accordion {
    background: none !important;
  }

  &.chayns_app,
  &.mobile_browser {
    .react-accordion {
      margin: 0;
    }
  }
}

.bar__tapp-wrapper {

  path {
    fill: #808080;
  }

  &.sites_tapp_active {
    path {
      fill: var(--chayns-color--primary);
    }
  }

  &.dark_mode {
    &.sites_tapp_active {
      path {
        fill: white;
      }
    }
  }
}

html {
    overflow: hidden !important;
}
