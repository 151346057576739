.image-shadow {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: block;
  z-index: 107;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.image-wrapper {
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  max-width: 1350px;
  position: fixed;
  z-index: 108;
  display: flex;
  width: 100%;
  left: 50%;
  top: 50%;
  user-select: none;

  &__nav-button {
    transition: opacity .3s ease-in-out;
    justify-content: center;
    will-change: opacity;
    align-items: center;
    cursor: pointer;
    opacity: 0.15;
    display: flex;
    height: 25vh;
    color: white;
    width: 10%;

    &--hide {
      display: none;
    }

    &__icon {
      font-size: 30px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__image-box {
    width: 80%;

    &__image-container {
      justify-content: center;
      position: relative;
      display: flex;

      &--hide {
        display: none;
      }

      &__image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          max-height: 90vh;
          max-width: 100%;
        }
      }

      &__nav-button {

      }

      &__img-button {
        position: absolute;
        cursor: pointer;
        width: 35%;
        bottom: 0;
        top: 0;

        &--prev {
          left: 0;
        }

        &--next {
          right: 0;
        }

        &--hide {
          display: none;
        }
      }
    }
  }
}
